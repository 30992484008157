<template>
  <tr :style="{'background': props.props.selected ? 'lightyellow' : '', 'color': props.props.selected ? '#000' : ''}">
    <td v-if="config.config.fieldsSelected" style="min-width: 30px !important;">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      {{props.item.id}}
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <span>{{props.item.nome_disp}}</span>
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <div v-if="props.item.ativo_disp == 'active'" style="width: 100%; text-align: center;">
        <v-icon :style="{color: props.item.status_disp ? 'green' : 'red'}" v-html="props.item.status_disp ? 'arrow_upward' : 'arrow_downward'"></v-icon><br>
        <strong :style="{color: props.item.status_disp ? 'green' : 'red'}" v-html="props.item.status_disp ? 'Disponível' : 'Indisponível'"></strong>
      </div>
      <div v-else style="width: 100%; text-align: center;">
        <v-icon>visibility_off</v-icon><br>
        <strong>Desativado</strong>
      </div>
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <span>{{props.item.ip_disp || '-'}}</span>
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <span>{{props.item.network.name || '-'}}</span>
    </td>
    <td class="justify-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn small outline @click="pingDialogAction(true, props.item.servidor_dados_id, props.item.ip_disp)">
              <v-icon>invert_colors</v-icon>
              Pingar
            </v-btn>
          </div>
        </template>
        <span>Clique para pingar este dispositivo.</span>
        <!-- <span v-else>O servidor deste dispositivo está offline ou desatualizado.</span> -->
      </v-tooltip>
      <ping-dialog v-if="pingDialog.active" :network-server-id="pingDialog.server" :host-to-ping="pingDialog.host" :active="pingDialog.active" v-on:active="pingDialogAction"></ping-dialog>
    </td>
    <td class="justify-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn icon link :to="{ name: 'device', params: { id: props.item.id } }">
              <v-icon>drag_indicator</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Acessar interfaces do dispositivo</span>
      </v-tooltip>
      <ping-dialog v-if="pingDialog.active" :network-server-id="pingDialog.server" :host-to-ping="pingDialog.host" :active="pingDialog.active" v-on:active="pingDialogAction"></ping-dialog>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  import PingDialog from '@/modules/network/pages/NetworkServer/Dialogs/PingDialog'
  export default {
    name: 'NetworkDevices',
    data () {
      return {
        pingDialog: {
          active: false,
          host: null,
          server: null
        }
      }
    },
    methods: {
      pingDialogAction: function (active = false, server = null, host = null) {
        this.pingDialog.host = host
        this.pingDialog.server = server
        this.pingDialog.active = active
      },
      pingIsAvaiable: function (device) {
        return device.network_server.status && device.network_server.last_interaction_in_minutes < 5
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions,
      PingDialog
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>